import React, { useEffect, useState } from 'react';
import {
  TextField,
  Typography,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Field, FormSpy } from 'react-final-form';
import map from 'lodash/map';

import { filedToCurrentLanguage } from '../../../utils';
import { DateField } from './date'
import { FileUploader } from './index';
import AutoCompleteField from '../../../utils/autocomplete';
import api from '../../../redux/api/index';

type Props = {
  files: [],
  setFiles: Function,
}

export const GeneralData = ({ setFiles, files }: Props) => {
  const [countries, setCountries] = useState([]);
  const [properties, setProperties] = useState([]);
  const [services, setServices] = useState([]);
  
  useEffect(() => {
    (async () => {
      const propertiesData = await api.get('properties');
      setProperties(map(propertiesData, item => ({ ...item, name: filedToCurrentLanguage(item.name,'ro') })));
      const countriesList = await api.get('countries');
      setCountries(countriesList);
      const servicesData = await api.get('services');
      setServices(map(servicesData, item => ({ ...item, name: filedToCurrentLanguage(item.name,'ro') })));
    })();
  }, []);
  
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Date generale
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field name="firstName">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                id="firstName"
                value={value}
                onChange={onChange}
                name={name}
                label="Nume administrator"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="lastName">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                id="lastName"
                value={value}
                onChange={onChange}
                name={name}
                label="Prenume administrator"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Field name="name">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                id="name"
                value={value}
                onChange={onChange}
                name={name}
                label="Denumirea completă a întreprinderii/organizației conform certificatului de înregistrare"
                fullWidth
                autoComplete="given-name"
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          
          <Field name="registeredDate">
            {({ input: { value, onChange, name } }) => (
              <DateField label="Data înregistrării întreprinderii/organizației" onChange={onChange} value={value} />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="idno">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                id="idno"
                value={value}
                onChange={onChange}
                name={name}
                type="number"
                label="IDNO – cod fiscal"
                fullWidth
                autoComplete="given-name"
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,13)
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="numberOfEmployees">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                id="numberOfEmployees"
                value={value}
                onChange={onChange}
                name={name}
                type="number"
                label="Numărul mediu de salariaţi (pers.)"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <Field name="participationWithForeignCapital">
            {({ input: { value, onChange, name } }) => (
              <FormControlLabel
                control={<Checkbox color="secondary" name={name} value={value} onChange={onChange} />}
                label="Capital străin"
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSpy subscription={{values: true}}>
            {({values}) => values.participationWithForeignCapital ? (
              <Field name="countryId">
                {({ input: { value, onChange, name } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    name={name}
                    label="Selectați tara sursă de capital străin"
                    fullWidth
                    select
                  >
                    {map(countries, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                  </TextField>
                )}
              </Field>
            ) : null}
          </FormSpy>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="propertyId">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                value={value}
                onChange={onChange}
                name={name}
                label="Forma de proprietate"
                fullWidth
                select
              >
                {map(properties, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
              </TextField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Field name="description">
            {({ input: { value, onChange, name } }) => (
              <TextField
                id="description"
                value={value}
                onChange={onChange}
                name={name}
                multiline
                label="Standarde de management implementat"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={12}>
          <Field
            name="priorityCountries"
            multiple
            selected={[]}
            label="Țările prioritare de cooperare"
            component={AutoCompleteField}
            placeholder="Țările prioritare de cooperare"
            options={map(countries, ({ id, name }) => ({ label: name, value: +id }))}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Field
            name="services"
            multiple
            selected={[]}
            label="Servicii"
            component={AutoCompleteField}
            placeholder="Servicii"
            options={map(services, ({ id, name }) => ({ label: name, value: +id }))}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Field name="request">
          {({ input: { value, onChange, name } }) => (
            <FormControlLabel
              control={<Checkbox color="secondary" name={name} value={value} onChange={onChange} />}
              label="Solicit primirea intreprinderii/organizatiei in calitate de membru al CCI"
            />
          )}
        </Field>
      </Grid>
  
      <Grid item xs={12}>
        <FileUploader setFiles={setFiles} files={files} />
      </Grid>
    </React.Fragment>
  );
};
