export const validateForm = (values, message) => {
  const errors = {};
  
  if (!values.name) {
    errors.name = message;
  }
  
  if (!values.idno) {
    errors.idno = message;
  }
  
  if (!values.firstName) {
    errors.idno = message;
  }
  
  if (!values.lastName) {
    errors.idno = message;
  }
  
  if (!values.agrees) {
    errors.agrees = message;
  }
  
  if (!values.email) {
    errors.email = message;
  }
  
  return errors;
};
