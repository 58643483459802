import React from 'react';
import styled from '@emotion/styled';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';

type Props = {
  handleClick: Function,
}

export const Card = ({ handleClick }: Props) => {
  return (
    <Wrapper>
      <AlertWrapper>Felicitari, ati devenit membru cci:</AlertWrapper>
      <DownloadBtn onClick={() => handleClick('card')} variant="contained" color="primary" type="button">
        Descarca CARNET DE MEMBRU
      </DownloadBtn>
      <DownloadBtn onClick={() => handleClick('patent')} variant="contained" color="primary" type="button">
        Descarca CERTIFICAT DE MEMBRU
      </DownloadBtn>
    </Wrapper>
  )
};

const Wrapper = styled('div')`
  padding: 24px;
  text-align: center;
`;

const AlertWrapper = styled(Alert)`
  margin: 16px 0;
`;

const DownloadBtn = styled(Button)`
  margin: 0 16px;
`;
