import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import styled from '@emotion/styled';
import { Form as FormWrapper } from 'react-final-form';
import { CssBaseline, AppBar, Toolbar, Paper, Stepper, makeStyles, Step, StepLabel, Button, Typography } from '@material-ui/core';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import logo from '../../logo.png';
import api from '../../redux/api/index';
import PaymentContainer from './payment';
import { validateForm } from './components/validations';
import { GeneralData, Address, Contacts, Areas, Card } from './components/index';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['Date generale', 'Domenii de activitate', 'Date de contact'];

const Create = () => {
  const classes = useStyles();
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const [activeStep, setActiveStep] = useState(0);
  const [hasSent, setRequestStatus] = useState(false);
  const [request, setRequest] = useState(null);
  const [details, setDetails] = useState({});
  const [files, setFiles] = useState([]);
  
  const initialValues = useMemo(() => ({
    name: '',
    cityId: '',
    agrees: false,
    isAgree: false,
    request: false,
    participationWithForeignCapital: false,
    registeredDate: new Date(),
    foundationDate: new Date(),
  }), []);
  
  const getStepContent = useCallback(step => {
    switch (step) {
      case 0:
        return <GeneralData setFiles={setFiles} files={files} />;
      case 1:
        return <Areas />;
      case 2:
        return (
          <React.Fragment>
            <AddressWrapper />
            <br/><br/>
            <Contacts />
          </React.Fragment>
        );
      default:
        throw new Error('Unknown step');
    }
  }, [files]);
  
  const handleNext = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, [activeStep]);

  const handleBack = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);
  
  const onSubmit = useCallback(async model => {
    try {
      await api.post('members/request', {
        ...model,
        files,
        social: JSON.stringify(get(model, 'social', [])),
        address: JSON.stringify({
          // street: get(model, 'street', null),
          postCode: get(model, 'postCode', null),
          phone: get(model, 'phone', null),
          home: get(model, 'home', null),
          office: get(model, 'office', null),
        })
      });
      setRequestStatus(true);
      return toastr.success('Datele au fost trimise cu succes');
    } catch (e) {
      console.warn(e);
    }
  }, [files]);
  
  const downloadCard = useCallback((type) => {
    return api.get(`request/card/${get(query,'card')}/${type}`, { responseType: 'blob' }).then(data => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      const fileName = type === 'card' ? 'Carnet de membru' : 'Certificat de membru';
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }, [query]);
  
  const hasCard = !!get(query,'card') && !isEmpty(details);
  
  useEffect(() => {
    (async () => {
      if (get(query,'uuid') && !request) {
        const data = await api.get(`request/public/${get(query,'uuid')}`);
        setRequest(data);
      }
      if (get(query,'card') && isEmpty(details)) {
        const data = await api.get(`request/public/${get(query,'card')}`);
        setDetails(data);
      }
    })();
  }, [query, request, hasCard, details]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            <Logo src={logo} />
          </Typography>
        </Toolbar>
      </AppBar>
      {hasCard && <Card handleClick={downloadCard} />}
      {!isEmpty(request) ? (<PaymentContainer request={request} />) : null}
      {isEmpty(request) && !hasCard ? (
        <>
          {hasSent ? (
            <AlertWrapper>
              Mulțumim pentru cererea expediată. Despre decizia luată veți fi informați pe adresa de email indicată.<br/>
              <SuccessIcon />
            </AlertWrapper>
          ) : (
            <FormWrapper
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={errors => validateForm(errors, 'Acest camp este obligatoriu')}
              render={({ handleSubmit, form, submitting, pristine, valid, values , hasSubmitErrors, errors}) => (
                <form onSubmit={handleSubmit}>
                  <main className={classes.layout}>
                    <Paper className={classes.paper}>
                      <Typography component="h1" variant="h4" align="center">
                        Cerere de obținere a statutului de membru al CCI a RM
                      </Typography>
                      <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                      <React.Fragment>
                        {activeStep === steps.length ? (
                          <React.Fragment>
                            <Typography variant="h5" gutterBottom>
                              Va multumim pentru aplicare.
                            </Typography>
                            <Typography variant="subtitle1">
                              Spre adresa dvs a fost trimis un mesaj de confirmare.
                            </Typography>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {getStepContent(activeStep)}
                            <div className={classes.buttons}>
                              {activeStep !== 0 && (
                                <Button onClick={handleBack} className={classes.button} type="button">
                                  Pagina precedentă
                                </Button>
                              )}
                              {activeStep === 2 ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={submitting}
                                  className={classes.button}
                                >
                                  Trimite
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="button"
                                  onClick={handleNext}
                                  className={classes.button}
                                >
                                  {activeStep === steps.length - 1 ? 'Trimite' : 'Următorul pas'}
                                </Button>
                              )}
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    </Paper>
                  </main>
                </form>
              )}
            />
          )}
        </>
      ) : null}
    </React.Fragment>
  );
};

const Logo = styled('img')`
  width: 85px;
  margin: 8px 0;
`;

const AlertWrapper = styled(Alert)`
  margin: 16px 0;
`;

const AddressWrapper = styled(Address)`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
`;

const SuccessIcon = styled(ThumbUp)`
  font-size: 70px;
`;

export default Create;