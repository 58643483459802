import React  from 'react';
import {TextField, Grid, Typography, FormControlLabel, Checkbox} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Field } from 'react-final-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import LinkedIn from '@material-ui/icons/LinkedIn';

export const Contacts = () => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Date de contact
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Field name="phone" type="checkbox">
            {props => (
              <MuiPhoneNumber
                data-cy="phone"
                fullWidth
                defaultCountry={'md'}
                label="Telefon mobil"
                preferredCountries={['md', 'ro', 'ru']}
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="webPage">
            {({ input: { value, onChange, name } }) => (
              <TextField
                value={value}
                onChange={onChange}
                name={name}
                label="Pagina web"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="email">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                value={value}
                onChange={onChange}
                name={name}
                type="email"
                label="E-mail"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={12}>
          <Field name="social[0]">
            {({ input: { value, onChange, name } }) => (
              <TextField
                value={value}
                onChange={onChange}
                name={name}
                label="Facebook"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Facebook />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={12}>
          <Field name="social[1]">
            {({ input: { value, onChange, name } }) => (
              <TextField
                value={value}
                onChange={onChange}
                name={name}
                label="Instagram"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Instagram />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={12}>
          <Field name="social[2]">
            {({ input: { value, onChange, name } }) => (
              <TextField
                value={value}
                onChange={onChange}
                name={name}
                label="LinkedIn"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedIn />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name="agrees">
            {({ input: { value, onChange, name } }) => (
              <FormControlLabel
                control={<Checkbox color="secondary" name={name} value={value} onChange={onChange} />}
                label="Prin prezenta declaram pe propria raspundere, ca toate datele mentionate mai sus sunt autentice si ne asumam responsabilitatea deplina, confirm prevederilor legislatiei in vigoare, pentru informatia si documentele prezentate."
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Field name="isAgree">
            {({ input: { value, onChange, name } }) => (
              <FormControlLabel
                control={<Checkbox color="secondary" name={name} value={value} onChange={onChange} />}
                label="Sunt de acord cu prelucrarea datelor cu caracter personal "
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};