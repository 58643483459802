import React, { useEffect, useState } from 'react';
import { Field } from 'react-final-form';
import { Grid, Typography } from '@material-ui/core';
import map from 'lodash/map';

import api from '../../../redux/api/index';
import { filedToCurrentLanguage } from '../../../utils';
import AutoCompleteField from '../../../utils/autocomplete';

export const Areas = () => {
  const [activityDirections, setActivityDirections] = useState([]);
  const [services, setServices] = useState([]);
  
  useEffect(() => {
    (async () => {
      const activityDirectionsList = await api.get('activityDirections', { params: { noPagination: true } });
      const servicesList = await api.get('itemCategories', { params: { noPagination: true } });
      setActivityDirections(map(activityDirectionsList, item => ({ ...item, name: filedToCurrentLanguage(item.name,' ro') })));
      setServices(map(servicesList, item => ({ ...item, name: filedToCurrentLanguage(item.name,' ro') })));
    })();
  }, []);
  
  
  return (
    <div>
      <Typography variant="h6">
        Domenii de activitate
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Field
            name="activities"
            multiple
            selected={[]}
            label="Domenii de activitate *"
            required
            component={AutoCompleteField}
            placeholder="Domenii de activitate"
            options={map(activityDirections, ({ id, name }) => ({ label: name, value: +id }))}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <Typography variant="h6" gutterBottom>
        Categorii de produse
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Field
            name="itemCategories"
            multiple
            required
            selected={[]}
            label="Categorii de produse *"
            component={AutoCompleteField}
            placeholder="Categorii de produse"
            options={map(services, ({ id, name }) => ({ label: name, value: +id }))}
          />
        </Grid>
      </Grid>
    </div>
  );
};
