import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { PictureAsPdf as PictureAsPdfIcon } from '@material-ui/icons';

import api from '../../../redux/api';

type Props = {
  files: [],
  setFiles: Function,
}

const FileUploader = ({ files, setFiles }: Props) => {
  const data = useMemo(() => new FormData(), []);
  
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader();
      
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => alert('A aparut o eroare la incarcare, mai incercati');
      reader.onload = () => {
        data.append('application', file);
        api.post('request/pdf', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(data => {
          if (data.status) {
            setFiles([...files, { name: file.name, path: data.path }]);
          } else {
            alert('Eroare la incarcare, incercati mai tarziu')
          }
        });
      };
      reader.readAsArrayBuffer(file);
    })
  }, [data, setFiles, files]);
  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: "application/pdf", multiple: true });

  return (
    <Wrapper {...getRootProps()}>
      <List>
        {files.map(({ name, path }) => (
          <File>
            <PictureAsPdfIcon />
            {name}
          </File>
        ))}
      </List>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p>Drop the files here ...</p> :
          <p><strong>Atașați extrasul *</strong></p>
      }
    </Wrapper>
  )
};

const Wrapper = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  
  p {
    margin: 0;
  }
`;

const List = styled('div')`
  display: flex;
  width: 100%;
`;

const File = styled('div')`
  width: 33.3%;
  display: flex;
  align-items: center;
`;

export default FileUploader;