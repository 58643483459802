import { ThemeProvider, Global, css } from '@emotion/react';

import theme from './theme/default';
import LayoutContainer from './containers/layout/layout';

function App() {
  const bodyStyles = css`
    body {
      margin: 0;
      padding: 0;
      font-family: Roboto, sans-serif;
    }
  `;
  
  return (
    <>
      <Global styles={bodyStyles} />
      <ThemeProvider theme={theme}>
        <LayoutContainer />
      </ThemeProvider>
    </>
  );
}

export default App;
