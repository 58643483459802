import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import get from 'lodash/get';


import api from '../../redux/api/index';

type Props = {
  request: {},
};

const Payment = ({ request }: Props) => {
  const [isPaid, setPaid] = useState(false);
  const handlePayment = useCallback(() => {
    api.put('requests/status', {
      uuid: get(request, 'uuid'),
      status: 'paid',
    }).then(() => {
      setPaid(true);
    }).catch(() => {
      setPaid(false);
    })
  }, [request]);
  
  const handleDownloadTicket = useCallback(() => {
    return api.get(`request/generate/${get(request, 'uuid')}`, { responseType: 'blob' }).then(data => {
      const url = window.URL.createObjectURL(new Blob(["\ufeff", data], {
        type: "text/plain;charset=utf-8;"
      }));
      const link = document.createElement('a');
      const fileName = 'Payment Ticket';
      link.href = url;
      link.setAttribute('download', `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  }, [request]);
  
  return (
    <Wrapper>
      <div>Bine ati revenit <strong>{get(request, 'firstName')} {get(request, 'lastName')}</strong>:</div>
      {isPaid || get(request, 'status') === 'paid' ? (
        <Alert>Serviciul a fost achitat cu succes, sunteti membru CCI</Alert>
      ) : (
        <>
          Cererea dvs a fost aprobata, va rugam sa achitati serviciul:
          <br />
          <br />
          <div>Pretul pentru serviciile CCI: <b>{get(request, 'amount')} MDL</b></div><br />
          <div>Pretul pentru cotizatie: <b>{get(request, 'fee')} MDL</b></div><br /><br />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleDownloadTicket}
          >
            Descarcati contul de plata
          </Button>
          {/*<Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handlePayment}
          >
            Achita
          </Button>
          <form action="https://testmpay.gov.md" method="post">
            <input type="hidden" name="ServiceID" value="CCI03"/>
            <input type="hidden" name="ReturnUrl" value="https://membrii.chamber.md"/>
            <input type="hidden" name="OrderKey" value="1"/>
            <input type="submit" value="Pay" className="btn btn-primary btn-block w-auto"/>
          </form>*/}
        </>
      )}
    </Wrapper>
  )
};

const Wrapper = styled('div')`
  display: flex;
  height: 69vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export default Payment;