import React, { useEffect, useState } from 'react';
import { FormSpy, Field } from 'react-final-form';
import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import map from 'lodash/map';
import get from 'lodash/get';

import api from '../../../redux/api/index';

type Props = {
  className?: string,
}

export const Address = ({ className }: Props) => {
  const [cities, setCities] = useState([]);
  const [villages, setVillages] = useState([]);
  
  useEffect(() => {
    (async () => {
      const citiesList = await api.get('cities');
      setCities(citiesList);
    })();
  }, []);
  
  const onChangeCity = async id => {
    const villageList = await api.get(`villages/${id}`);
    setVillages(villageList);
  };
  
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Adresa
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Field name="cityId">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                value={value}
                onChange={(e, { props: { value } }) => {
                  onChange(e);
                  return onChangeCity(value);
                }}
                name={name}
                label="Raionul"
                fullWidth
                select
              >
                {map(cities, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
              </TextField>
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormSpy subscription={{values: true}}>
            {({values}) => (
              <Field name="villageId">
                {({ input: { value, onChange, name } }) => (
                  <TextField
                    required
                    value={value}
                    onChange={onChange}
                    name={name}
                    label="Localitatea"
                    disabled={!get(values, 'cityId')}
                    fullWidth
                    select
                  >
                    {map(villages, ({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
                  </TextField>
                )}
              </Field>
            )}
          </FormSpy>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Field name="legalStreet">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                value={value}
                onChange={onChange}
                name={name}
                label="Adresa juridică"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="postCode">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                value={value}
                onChange={onChange}
                name={name}
                label="Cod poştal"
                fullWidth
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field name="office">
            {({ input: { value, onChange, name } }) => (
              <TextField
                required
                value={value}
                onChange={onChange}
                name={name}
                label="Bloc, oficiu"
                fullWidth
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </div>
  );
};