import React, { ReactNode } from 'react';
import { Link, Typography } from '@material-ui/core';

import CreateContainer from '../members/create';

type Props = {
  children?: ReactNode,
};

const LayoutContainer = ({ children }: Props) => (
  <div>
    {children}
    <CreateContainer />
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://chamber.md/">
        CCI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  </div>
);

export default LayoutContainer;