import axios from 'axios';
import get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import CONFIG from '../../config';

const instances = [];

function apiFactory(url, interceptors) {
  const api = axios.create({
    baseURL: `${CONFIG.api}/api`,
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  });
  
  instances.push({ instance: api, url, interceptors });
  return api;
}

const api = apiFactory('', {});

export const authApi = apiFactory('', {});

export default api;

authApi.interceptors.response.use(response => Promise.resolve(response.data || response));
api.interceptors.response.use(response => Promise.resolve(get(response, 'data', response)), error => {
  const { response } = error;
  if (response && response.status === 422) {
    const errorList = get(response, 'data.errors', []);
    const errors = Object.keys(errorList).map(key => `${errorList[key]}`).join('\n');
    toastr.error(errors);
  }
  return Promise.reject({ payload: [], meta: error });
});
